import { CHIPS } from "../../constants/highlightsPage.constants";
import { ICategory } from "../hooks/useGetHighlightsTabs";

export const IMPORTANT_SUFFIX = {
  points: "pts",
  money: "M",
  rank: "",
  int: "",
  other: "",
} as const;

export type TCategoryId = keyof typeof CHIPS;
export type TImportantSuffix = keyof typeof IMPORTANT_SUFFIX;

export interface IClassicLeagues {
  id: number;
  name: string;
  rank_count?: number;
  entry_rank?: number;
  entry_last_rank?: number;
  rank_change?: {
    isUp: boolean;
    change_count: number;
  };
}

export interface IHighlightCardDetails {
  id: number;
  entry: number;
  entry_name: string;
  player_name: string;
  rank: number;
  [key: string]: string | number;
}

export interface IAllHighlights {
  id: string;
  view_from_bottom_desc: string;
  view_from_top_desc: string;
  view_from_bottom_invalid?: boolean;
  view_from_top_invalid?: boolean;
  details: IHighlightCardDetails[];
}

export interface IImportantProperties {
  key: string;
  display_name: string;
  type: TImportantSuffix;
  order: number;
  color: "primary" | "white";
}

export interface IHighlightsInfo {
  _id: string;
  highlight_id: string;
  category_id: TCategoryId;
  display_name: string;
  description: string;
  icon: string;
  view_from: "top" | "bottom";
  highlight_order: number;
  important_properties: IImportantProperties[];
  invalid: string;
}

export interface IHighlightCard extends IAllHighlights, IHighlightsInfo {}

export interface ISingleHighlightCard
  extends IAllHighlights,
    IHighlightsInfo,
    ICategory {}

export interface IFplManager {
  id: number;
  event_total: number;
  player_name: string;
  rank: number;
  last_rank: number;
  rank_sort: number;
  total: number;
  entry: number;
  entry_name: string;
}

export interface IFplAllInfoPhase {
  id: number;
  name: string;
  start_event: number;
  stop_event: number;
}

export interface IGlobalInfo {
  phases: IFplAllInfoPhase[];
  current_gw: number;
}
