export const SOCIAL_MEDIA_LINKS = {
  TWITTER: "https://x.com/champfpl?s=21",
  REDDIT: "https://www.reddit.com/u/FPL-Champ/s/tpNGLYn8X1",
};

export const ERROR_MODAL_CONTENT = {
  TITLE: "Oh Snap!",
  CLOSE_BUTTON_TITLE: "Close",
};

export const LOADING_SCREEN_CONTENT = {
  TITLE: "FPL CHAMPS",
};

export const FALLBACK_UI = {
  TITLE: "Something went wrong, we apologize for that, we'll work on fixing it",
  BTN_TEXT: "Go to Home Page",
};

export const MODAL_ERROR_MESSAGES = {
  SELECT_LEAGUE_WITH_MORE_THAN_500_ENTRY:
    "We currently only support classic leagues up to 500 entries.",
  GAME_WEEK_IS_UPDATING: "The game is being updated.",
  LOGOUT: {
    TITLE:
      "Would you like to change your team or to log out from your account?",
    LOGOUT_BUTTON: "Log out",
    CHANGE_TEAM_BUTTON: "Change Team",
  },
};

export const SNACKBAR_MESSAGES = {
  REGISTER_FIRST_TEAM_DETAILS: "Please register first then add team Id",
  RESET_PASSWORD_EMAIL_SENT: "Email sent to reset your password",
  CONFIRMATION_EMAIL_SENT: "Check your email for a confirmation mail",
  PASSWORD_RESET: "Password reset successfully",
  HIGHLIGHTS_LOADING_8_SEC:
    "Hang on while we do our magic, this shouldn’t be long now",
  HIGHLIGHTS_LOADING_12_SEC: "Almost there now...",
};

export const GAME_WEEK_DROPDOWN_CONTENT = {
  LOADING_TEXT: "Loading Game week",
  DROPDOWN_ITEM_TEXT: (gameWeekNumber: number, isCurrent: boolean) =>
    `Game week ${gameWeekNumber} ${isCurrent ? "(Current)" : ""}`,
};

export const SELECT_LEAGUE_DROPDOWN_CONTENT = {
  LOADING_TEXT: "Loading your league",
};

export const PHASE_DROPDOWN = {
  TITLE: "Select Phase: ",
  LOADING_TEXT: "Loading Phases",
};

export const SIDE_NAVIGATION_BAR_TITLES = {
  MAIN_NAVIGATION_TITLES: {
    HOME: "Home",
    HIGHLIGHTS: "Highlights",
    LEAGUES: "Leagues",
    TRANSFERS: "Transfers",
    POINTS: "Points",
    FIXTURES: "Fixtures",
  },

  SOCIAL_MEDIA_TITLE: {
    REDDIT: "Follow us on Reddit",
    TWITTER: "Follow us on X",
  },

  LOGOUT: "Logout",
  SIGNUP: "Sign Up",
} as const;

export const FOOTER = {
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_OF_SERVICE: "Terms of service",
};

export const APP_TOOLTIP_CONSTANTS = {
  BTN: "Cool",
  MESSAGES: {
    HIGHLIGHTS: {
      amusing: {
        TITLE: "Amusing League Highlights",
        SUBTITLE:
          "These are special highlights that you might find amusing to know about managers in your selected league.",
      },
      stinking: {
        TITLE: "Stinking League Highlights",
        SUBTITLE:
          "These are special highlights, but not the good type, that you might find amusing to know about managers in your selected league.",
      },
      basic: {
        TITLE: "Basic League Highlights",
        SUBTITLE:
          "These are basic, yet fun highlights to know about your selected league.",
      },
      all: { TITLE: undefined, SUBTITLE: undefined },
    },

    CAPTAINED_BY_COUNT: {
      TITLE: "Captained by count",
      SUBTITLE:
        "Number of managers within your selected league who captained this player.",
    },
    OWNERSHIP_IN_LEAGUE: {
      TITLE: "Ownership in the league",
      SUBTITLE:
        "The percentage of managers within your selected league who own this player.",
    },
    CAPTAINS_IN_FIXTURE: {
      TITLE: "Captains in this fixture",
      SUBTITLE:
        "The number of players captained by managers within your selected league playing in this fixture.",
    },
    FIXTURE_IMPORTANT: {
      normal: {
        TITLE: "Normal Importance",
        SUBTITLE:
          'Based on the number of players you and other managers in your league own from this fixture, this fixture is classified as having "Normal" importance for you.',
      },
      insignificant: {
        TITLE: "Insignificant Importance",
        SUBTITLE:
          'Based on the fact that you and other managers in your league don’t own many players from this fixture, this fixture is classified as having "Insignificant" importance to you.',
      },
      important: {
        TITLE: "Important fixture",
        SUBTITLE:
          'Based on the fact that you and other managers in your league own many players from this fixture, this fixture is classified as having "Important" importance to you.',
      },
      crucial: {
        TITLE: "Crucial Importance",
        SUBTITLE:
          'Based on the fact that you and other managers in your league own a great number of players from this fixture, this fixture is classified as having "Crucial" importance to you.',
      },
    },
  },
};

export const SHOW_ME_COOL_BTN = {
  TITLE: "Show me something cool!",
  MINI_LEAGUE: {
    TITLE: "Cool stuff you can find out about your league!",
  },
  FIXTURE: {
    TITLE: "Cool stuff related to this GW’s fixtures!",
  },
};

export const FLOATING_SIGN_UP = {
  title: "Create an account for more FPL highlights",
  BTN: "SIGN UP",
};
