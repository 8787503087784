import { useEffect, useState } from "react";
import {
  TeamFormation,
  TeamBanner,
  TeamBannerSkeleton,
  TeamFormationSkeleton,
  EplPlayerSummary,
} from "./components";
import { Box, Stack, Typography, css } from "@mui/material";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { PageHeader, SelectGameWeekDropDown, Skeleton } from "../../components";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { PATH_PARAMS } from "../../config/routes";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { Helmet } from "react-helmet";
import { FPL_CHIPS } from "../../constants/view-team-pages.constants";
import { bgColorDark } from "../../config/theme";
import { ITeamInfo, useGetTeamInfo } from "../../utils/hooks/useGetTeamInfo";

/**
 * Main function that renders the View Team Page
 */

export const ViewTeamPage = () => {
  const urlParams = useParams();
  const { user } = useUserContext();
  const { teamInfo: teamData } = useGetTeamInfo({
    teamId: urlParams[PATH_PARAMS.TEAM_ID]!,
  });
  const [playerSummary, setPlayerSummary] = useState<
    ITeamInfo["picks"][0] | null
  >(null);
  const { getTeamViewPath } = useGetUrl();
  const navigate = useNavigateState();

  useEffect(() => {
    if (!urlParams[PATH_PARAMS.TEAM_ID] && user) {
      navigate(getTeamViewPath(user.teamId!), { replace: true });
    }
  }, [urlParams, user]);

  const handlePlayerClick = (eplPlayer: ITeamInfo["picks"][0]) => {
    setPlayerSummary(eplPlayer);
  };

  const closeEplPlayerSummary = () => {
    setPlayerSummary(null);
  };

  return (
    <Box>
      <Helmet>
        <title>{`Team - ${teamData ? teamData.name : ""}`}</title>
      </Helmet>

      {Boolean(playerSummary) && (
        <EplPlayerSummary
          open={Boolean(playerSummary)}
          onClose={closeEplPlayerSummary}
          eplPlayer={playerSummary}
        />
      )}

      <Stack css={rootStyle}>
        <Stack css={titleSectionStyles}>
          <Stack>
            <PageHeader
              title={teamData && teamData.name}
              isLoading={teamData === null}
            />
            <Typography variant="h2" css={fplManagerNameStyles}>
              {teamData ? (
                teamData.fpl_manager_name
              ) : (
                <Skeleton width={"50%"} />
              )}
            </Typography>
          </Stack>
          <SelectGameWeekDropDown
            lowestValue={teamData && teamData.started_event}
          />
          <span css={dividerStyles} />
        </Stack>
        <Box css={bannerContainerStyle}>
          {teamData != null ? (
            <TeamBanner
              entryHistory={teamData.entry_history}
              numberOfPlayers={teamData.number_of_players}
            />
          ) : (
            <TeamBannerSkeleton />
          )}
        </Box>

        {teamData ? (
          <Stack css={teamFormationContainer}>
            {teamData.active_chip && (
              <Typography css={playedChipStyles}>
                {FPL_CHIPS[teamData.active_chip].img && (
                  <img src={FPL_CHIPS[teamData.active_chip].img} />
                )}
                {FPL_CHIPS[teamData.active_chip].title}
              </Typography>
            )}
            <TeamFormation
              eplPlayerClick={handlePlayerClick}
              isTripleCapChipPlayed={Boolean(
                teamData.active_chip && teamData.active_chip === "3xc"
              )}
              teamPlayers={teamData.picks}
            />
          </Stack>
        ) : (
          <Stack
            css={[
              teamFormationContainer,
              css`
                background-color: ${bgColorDark};
              `,
            ]}
          >
            <Typography css={playedChipStyles}>
              <Skeleton />
            </Typography>
            <TeamFormationSkeleton />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const rootStyle = css`
  gap: 2rem;
`;

const fplManagerNameStyles = css`
  margin-top: 0.3rem;
  font-size: clamp(1rem, 3vw, 2rem);
  font-weight: 500;
`;

const bannerContainerStyle = css`
  width: 100%;
  max-width: clamp(27rem, 45vw, 40rem);
  margin-inline: auto;
`;

const teamFormationContainer = css`
  border-radius: 1rem;
  background-color: white;
  overflow: hidden;
  width: 100%;
  max-width: 45rem;
  margin-inline: auto;
  z-index: 1;
`;

const dividerStyles = css`
  display: block;
  width: 100%;
  height: 1px;
  margin-inline: auto;
  background: #838383;
  border-radius: 9999px;
`;

const titleSectionStyles = css`
  gap: 1rem;
`;

const playedChipStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #414141;
  width: 100%;
  margin-inline: auto;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 700;
`;
