import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { primaryColor, redColor, secondaryColor } from "../../../config/theme";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { mq } from "../../../config/styles";
import { Skeleton } from "../../../components";
import { formateNumbers } from "../../../utils/general.helpers";
import { MoreInfoModal } from "./MoreInfoModal";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../../config/routes";
import { VIEW_TEAM_PAGE } from "../../../constants/view-team-pages.constants";
import { ITeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";

interface ITeamBanner {
  entryHistory: ITeamInfo["entry_history"];
  numberOfPlayers: number;
}

const initialModalState = { isOpen: false, data: {} };

/**
 * A component that renders the team banner section in the View Team Page
 */

export const TeamBanner = (props: ITeamBanner) => {
  const {
    overall_rank,
    event_transfers,
    event_transfers_cost,
    previous_overall_rank,
    bank,
    value,
    all_season_transfers,
    total_points,
    players_points,
    rank,
  } = props.entryHistory;

  const [searchparams] = useSearchParams();
  const params = useParams();
  const [modal, setModal] = useState(initialModalState);

  useEffect(() => {
    if (searchparams.get(QUERY_PARAMS.TRANSFERS_INFO)) {
      handleTransfersMoreInfoClick();
    } else if (searchparams.get(QUERY_PARAMS.POINTS_INFO)) {
      handleGameWeekMoreInfoClick();
    } else if (searchparams.get(QUERY_PARAMS.OVERALL_INFO)) {
      handleOverallRankMoreInfoClick();
    } else {
      setModal(initialModalState);
    }

    // Remove params on page exit
    return () => {
      [...searchparams].forEach(([key, _]) => {
        if (
          [
            QUERY_PARAMS.OVERALL_INFO,
            QUERY_PARAMS.POINTS_INFO,
            QUERY_PARAMS.TRANSFERS_INFO,
          ].includes(key)
        ) {
          searchparams.delete(key);
        }
      });
    };
  }, [searchparams]);

  const updateQueryParams = (paramName: string) => {
    if (!searchparams.get(paramName)) {
      window.history.pushState(
        null,
        "",
        `?${paramName}=true&` + searchparams.toString()
      );
    }
  };

  const handleOnClose = () => {
    [...searchparams].forEach(([key, _]) => {
      if (Object.values(QUERY_PARAMS).includes(key)) {
        searchparams.delete(key);
      }
    });
    window.history.pushState(
      null,
      "",
      `./${params.teamId}?` + searchparams.toString()
    );
    setModal(initialModalState);
  };

  const handleTransfersMoreInfoClick = () => {
    updateQueryParams(QUERY_PARAMS.TRANSFERS_INFO);
    setModal({
      isOpen: true,
      data: {
        [VIEW_TEAM_PAGE.SQUAD_VALUE]: value / 10,
        [VIEW_TEAM_PAGE.MONEY_IN_THE_BANK]: bank / 10,
        [VIEW_TEAM_PAGE.ALL_SEASON_TRANSFERS]: all_season_transfers,
      },
    });
    TrackGoogleAnalyticsEvent(GA4_EVENTS.TRANSFERS_MORE_INFO_CLICKED);
  };

  const handleGameWeekMoreInfoClick = () => {
    updateQueryParams(QUERY_PARAMS.POINTS_INFO);
    setModal({
      isOpen: true,
      data: {
        [VIEW_TEAM_PAGE.POINTS_THIS_GW]: players_points.toLocaleString(),
        [VIEW_TEAM_PAGE.OVERALL_POINTS]: total_points.toLocaleString(),
      },
    });
    TrackGoogleAnalyticsEvent(GA4_EVENTS.POINTS_MORE_INFO_CLICKED);
  };

  const handleOverallRankMoreInfoClick = () => {
    updateQueryParams(QUERY_PARAMS.OVERALL_INFO);
    setModal({
      isOpen: true,
      data: {
        [VIEW_TEAM_PAGE.OVERALL_RANK]: overall_rank.toLocaleString(),
        [VIEW_TEAM_PAGE.GW_RANK]: rank.toLocaleString(),
        ...(previous_overall_rank && {
          [VIEW_TEAM_PAGE.RANK_DIFFERENCE]: (
            <Stack
              flexDirection={"row"}
              gap={2}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {(previous_overall_rank - overall_rank).toLocaleString()}
              <span
                css={[
                  overAllRankIcon(previous_overall_rank > overall_rank),
                  arrowRankInfo,
                ]}
              >
                <KeyboardArrowDownRoundedIcon />
              </span>
            </Stack>
          ),
        }),
        [VIEW_TEAM_PAGE.NUMBER_OF_PLAYERS]:
          props.numberOfPlayers.toLocaleString(),
      },
    });
    TrackGoogleAnalyticsEvent(GA4_EVENTS.RANK_MORE_INFO_CLICKED);
  };

  return (
    <Stack css={rootStyles}>
      <MoreInfoModal
        open={modal.isOpen}
        info={modal.data}
        onClose={handleOnClose}
      />
      <Stack css={teamBannerCardStyle()}>
        <p>
          <span>
            {event_transfers}
            <span css={transfersCostStyles}>{`${
              VIEW_TEAM_PAGE.TRANSFERS_COST_PREFIX
            }${event_transfers_cost * -1}${VIEW_TEAM_PAGE.POINTS_SUFFIX}${
              VIEW_TEAM_PAGE.TRANSFERS_COST_SUFFIX
            }`}</span>
          </span>
        </p>
        <p>{VIEW_TEAM_PAGE.TRANSFERS}</p>
        <a onClick={handleTransfersMoreInfoClick}>{VIEW_TEAM_PAGE.VIEW_MORE}</a>
      </Stack>
      <Stack css={teamBannerCardStyle(true)}>
        <p>
          <span>{players_points}</span>
        </p>
        <p>{VIEW_TEAM_PAGE.GW_POINTS}</p>
        <a onClick={handleGameWeekMoreInfoClick}>{VIEW_TEAM_PAGE.VIEW_MORE}</a>
      </Stack>
      <Stack css={teamBannerCardStyle()}>
        {previous_overall_rank && (
          <span css={overAllRankIcon(previous_overall_rank > overall_rank)}>
            <KeyboardArrowDownRoundedIcon />
          </span>
        )}
        <p>
          <span>{formateNumbers(overall_rank)}</span>
        </p>
        <p>{VIEW_TEAM_PAGE.OVERALL_RANK_LABEL}</p>
        <a onClick={handleOverallRankMoreInfoClick}>
          {VIEW_TEAM_PAGE.VIEW_MORE}
        </a>
      </Stack>
    </Stack>
  );
};

const rootStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: clamp(1rem, 1vw, 2rem);
`;

const overAllRankIcon = (isUp: boolean = false) => css`
  background-color: ${redColor};
  position: absolute;
  top: 0;
  right: 0;
  translate: 40% -40%;
  width: 1.5rem;
  aspect-ratio: 1/1;
  border-radius: 9999px;

  display: flex;
  align-items: center;
  justify-content: center;

  * {
    color: aliceblue;
  }

  ${isUp &&
  css`
    background-color: ${secondaryColor};
    & > * {
      rotate: 180deg;
    }
  `}
`;

const transfersCostStyles = css`
  margin-left: 0.2rem;
  font-weight: 400;
  font-size: clamp(1rem, 2vw, 2rem);
`;

const teamBannerCardStyle = (isMain?: boolean) => css`
  position: relative;
  background-color: aliceblue;
  color: #414141;
  text-align: center;
  font-weight: 600;
  width: clamp(100px, 23vw, 167.35px);
  height: clamp(120px, 16vw, 160px);
  padding: 0.5rem 0 0 0;
  border-radius: 1rem;

  a {
    display: block;
    background-color: ${primaryColor};
    padding: 0.3rem 0;
    color: white;
    text-decoration: underline;
    border: none;
    border-radius: 0 0 1rem 1rem;
    cursor: pointer;

    ${mq["md"]} {
      padding: 0.5rem 0;
    }
  }

  & > p {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: clamp(0.875rem, 1.5vw, 1.25rem);

    &:first-of-type {
      flex: 1;
      font-size: clamp(2rem, 4vw, 3.75rem);
      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 80%;
        background-color: #41414138;
      }
    }

    &:nth-of-type(2) {
      padding: 0.3rem 0;

      ${mq["md"]} {
        padding: 0.5rem 0;
      }
    }
  }

  ${isMain &&
  css`
    background: linear-gradient(to right, #00ff87, #02efff);
    width: clamp(130px, 29vw, 215.66px);
    height: clamp(150px, 20vw, 230px);
    p,
    span {
      scale: 1.3;
    }

    p {
      &:first-of-type::after {
        background-color: white;
        width: 60%;
      }

      &:nth-of-type(2) {
        padding: 0.5rem 0;

        ${mq["md"]} {
          padding: 1rem 0;
        }
      }
    }

    a {
      padding: 0.4rem 0;
      background-color: white;
      color: #414141;
      font-size: medium;

      ${mq["md"]} {
        padding: 0.7rem 0;
      }
    }
  `}
`;

const arrowRankInfo = css`
  position: static;
  translate: 0 0;
`;

export const TeamBannerSkeleton = () => {
  return (
    <Stack css={rootStyles}>
      {Array.from({ length: 3 }, (_, index) => (
        <Stack key={index} css={[teamBannerCardStyle(), skeletonCardStyle]}>
          <Skeleton sx={{ flex: 1.5 }} />
          <Skeleton height={2} />
          <Skeleton sx={{ flex: 1 }} />
        </Stack>
      ))}
    </Stack>
  );
};

const skeletonCardStyle = css`
  gap: 0.5rem;
  padding: 0.7rem;
  background-color: #7e83a911;

  &:nth-of-type(2) {
    scale: 1.3;
    ${mq["md"]} {
      scale: 1.1 1.3;
    }
  }

  ${mq["md"]} {
    padding: 1rem;
  }
`;
