import { AuthApiError, User } from "@supabase/supabase-js";
import { supabase } from "../config/supabase";
import { IUser } from "../context/UserContext";
import { IClassicLeagues } from "./types/types";

/** return and supabase user */
export const getUserSupabase = async () => {
  const {
    data: { user },
    error: getUserError,
  } = await supabase.auth.getUser();
  if (getUserError) throw getUserError;

  return user;
};

/** Formate supabase user object to match the type of the context users */
export const formatUser = (user: User | null) => {
  if (
    user &&
    user.email &&
    user.identities &&
    user.identities[0].identity_data
  ) {
    return {
      id: user.id,
      email: user.email,
      teamId: user.user_metadata.teamId,
      email_verified: user.identities[0].identity_data.email_verified,
      selectedLeagueId: user.user_metadata.selectedLeagueId,
      favoriteLeagueId: user.user_metadata.favoriteLeagueId,
    } as IUser;
  }
  return undefined;
};

/** Set the selected league in the user metadata and return a formatted user object */
export const setSelectedLeagueInUserMetaData = async (
  selectedLeagueId: string
) => {
  const user = await getUserSupabase();
  const {
    data: { user: newUser },
    error,
  } = await supabase.auth.updateUser({
    data: { ...user?.user_metadata, selectedLeagueId },
  });
  if (error) throw error;
  if (!user) throw new AuthApiError("", 401, "Unauthorized");
  return formatUser(newUser as User);
};

/** Set the Fav league in the user metadata and return a formatted user object */
export const setFavoriteLeagueInUserMetaData = async (
  favoriteLeagueId: string
) => {
  const user = await getUserSupabase();
  const {
    data: { user: newUser },
    error,
  } = await supabase.auth.updateUser({
    data: { ...user?.user_metadata, favoriteLeagueId },
  });
  if (error) throw error;
  if (!user) throw new AuthApiError("", 401, "Unauthorized");
  return formatUser(newUser as User);
};

/** Check if user has a teamId in the supabase metadata */
export const supabaseUserHasTeamId = async () => {
  const user = await getUserSupabase();
  return user?.user_metadata.teamId;
};

/** return league object  */
export const getLeagueById = (
  leagues: IClassicLeagues[],
  id: string | number
) => {
  return leagues.find((l) => l.id === Number(id));
};

/** Formats a large number into a string with appropriate suffixes (e.g., "k" for thousands, "M" for millions). */
export const formateNumbers = (num: number) => {
  switch (true) {
    case num > 10_000_000:
      return (num / 1_000_000).toFixed(1) + "M";
    case num > 1_000_000:
      return (num / 1_000_000).toFixed(2) + "M";
    case num > 100_000:
      return (num / 1_000).toFixed(0) + "k";
    case num > 10_000:
      return (num / 1_000).toFixed(1) + "k";
    case num > 1_000:
      return (num / 1_000).toFixed(2) + "k";
    default:
      return num;
  }
};
